<!--  -->
<template>
  <div>
    <!-- 顶部 -->
    <van-sticky>
      <div class="point_top">
        <i class="el-icon-arrow-left" @click="back()"></i>
        <div class="point_kind">出库信息</div>
        <div class="point_point"></div>
      </div>
    </van-sticky>

    <van-tabs>
      <van-tab title="出库扫描">
        <!--单据日期 出库类型 包装规格 供应商选择  -->
        <div class="point_main">
          <!-- 单据日期 -->
          <div class="every_option">
            <div style="display: flex">
              <div style="font-size: 5vw">单据日期：</div>
            </div>
            <div
              style="
                width: 57vw;
                height: 100%;
                display: flex;
                align-items: center;
              "
              @click="chooseDate(1)"
            >
              <!-- <div style="font-size:3.4vw;margin-left:7vw;" >{{query.startTime}}</div> -->
              <el-input v-model="dateVaule" size="small"></el-input>
            </div>
          </div>
          <!-- 日期选择 -->
          <van-popup v-model="choose_popup" position="bottom">
            <van-datetime-picker
              v-model="currentDate"
              type="date"
              title="选择年月日"
              :max-date="maxDate"
              @confirm="chooseDateBtn"
              @cancel="cancelBtn"
            />
          </van-popup>

          <!-- 单据类型 -->
          <div style="display: flex; align-items: center">
            <div style="font-size: 5vw">出库类型：</div>
            <el-select
              @change="styleList(value)"
              v-model="value"
              size="small"
              placeholder="请选择"
              style="width: 57vw"
            >
              <el-option
                v-for="item in throughtList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <!-- 包装类型 -->
          <div style="display: flex; align-items: center">
            <div style="font-size: 5vw">包装规格：</div>
            <el-select
              v-model="pack"
              size="small"
              placeholder="请选择"
              style="width: 57vw"
            >
              <el-option
                v-for="item in packList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <!-- 供应商选择 -->
          <div>
            <div style="display: flex; align-items: center; margin-left: 9vw">
              <div style="font-size: 5vw">客户：</div>
              <!-- <el-input v-model="valus" @blur="goods"></el-input> -->
              <el-autocomplete
                size="small"
                popper-class="my-autocomplete"
                v-model="state"
                :fetch-suggestions="querySearch"
                placeholder="请输入内容"
                @select="handleSelect"
                style="width: 57vw"
              >
                <i
                  class="el-icon-edit el-input__icon"
                  slot="suffix"
                  @click="handleIconClick"
                >
                </i>
                <template slot-scope="{ item }">
                  <div class="name">{{ item.客户名称 }}</div>
                </template>
              </el-autocomplete>
            </div>
          </div>
        </div>
        <!-- 药品搜索 -->
        <div
          style="
            width: 96%;
            height: 9vw;
            border-radius: 4.5vw;
            display: flex;
            align-items: center;
            background: #fff;
            color: black;
            margin-left: 2%;
            margin-top: 2vw;
            border: 1px solid rgba(190, 190, 190, 0.4);
          "
        >
          <img
            src="../assets/add/sousuo 拷贝.png"
            alt=""
            style="width: 20px; height: 20px; margin-left: 4vw"
          />
          <input
            type="text"
            placeholder="药品搜索"
            v-model="goodsVaule"
            style="
              margin-left: 2vw;
              border: 0;
              background-color: #fff;
              width: 80%;
              height: 8vw;
            "
          />
          <div style="margin-left: 3vw">
            <div
              @click="showPopup(goodsVaule)"
              style="
                width: 18vw;
                height: 8.5vw;
                border-radius: 0 4.5vw 4.5vw 0;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 5vw;
              "
            >
              搜索
            </div>

            <van-action-sheet
              v-model="show1"
              position="bottom"
              cancel-text="取消"
              close-on-click-action
            >
              <ul
                v-for="(item, index) in goodsList2"
                :key="index"
                style="font-size: 5vw; padding: 3vw"
              >
                <li
                  @click="onSelect(item)"
                  style="border-bottom: 1px solid #e7e8e8"
                >
                  <span>{{ index + 1 }}.</span>
                  {{ item.goodsNo }}/{{ item.goodsName }}/{{
                    item.specification
                  }}/{{ item.production_unit }}
                </li>
              </ul>
            </van-action-sheet>
          </div>
        </div>
        <!-- 监码扫描 手动输入来回切换 -->
        <div
          style="display: flex; justify-content: space-around; margin-top: 3vw"
        >
          <el-input
            size="small"
            v-model="goodsVauleMed"
            placeholder="请输入药品监码"
            style="width: 60vw"
            @focus="aaa()"
          >
          </el-input>
          <el-button
            v-if="jianma"
            type="primary"
            style="
              height: 8.5vw;
              display: flex;
              justify-content: space-around;
              align-items: center;
            "
            @click="scanBianmachuku()"
            >监码扫描</el-button
          >
          <el-button
            v-if="shoudong"
            type="primary"
            style="
              height: 8.5vw;
              display: flex;
              justify-content: space-around;
              align-items: center;
            "
            @click="gooodsScan1(goodsVauleMed)"
            >手动输入</el-button
          >
        </div>
        <el-divider></el-divider>
        <!-- <div @click="gooodsScan('11111111')">按钮</div> -->
        <!-- 药品信息 -->

        <div
          v-if="this.codeListchuku.length > 0"
          style="
            width: 94%;
            margin-left: 3%;
            border-radius: 3vw;
            margin-top: 1vw;
            height: auto;
          "
        >
          <div
            style="
              width: 100%;
              height: 10vw;
              background: #4bc4fa;
              border-radius: 2vw 2vw 0 0;
              display: flex;
              justify-content: space-around;
              align-items: center;
              color: #fff;
            "
          >
            <div style="margin-left: 2vw">货号</div>
            <div>名称</div>

            <div style="margin-left: 1vw">药品监码</div>
            <div>数量</div>
            <div>操作</div>
          </div>
          <div
            v-for="(item, index) in codeListchuku"
            :key="index"
            style="
              width: 100%;
              height: 50%;
              background: rgba(190, 190, 190, 0.2);
              color: #000;
              padding: 2vw 0;
              display: flex;
              justify-content: space-around;
              align-items: center;
              font-size: 4.5vw;
              border-radius: 0 0 0 0;
            "
          >
            <div style="width: 15vw; margin-right: 10vw">
              <el-tooltip placement="top">
                <div slot="content">{{ item.itemNo }}</div>
                <div style="overflow: hidden; white-space: nowrap; width: 13vw">
                  {{ item.itemNo }}
                </div>
              </el-tooltip>
            </div>
            <div style="width: 15vw; margin-left: -19vw; font-size: 4vw">
              <el-tooltip placement="top">
                <div slot="content">{{ item.itemName }}</div>
                <div style="overflow: hidden; white-space: nowrap; width: 12vw">
                  {{ item.itemName }}
                </div>
              </el-tooltip>
            </div>
            <div style="width: 15vw; margin-right: 2vw">
              <el-tooltip placement="top">
                <div slot="content">{{ item.traceCode }}</div>
                <div style="overflow: hidden; white-space: nowrap; width: 13vw">
                  {{ item.traceCode }}
                </div>
              </el-tooltip>
            </div>
            <div>{{ item.actualQuantity }}</div>
            <div style="color: red" @click="delChuku(index)">删除</div>
          </div>
        </div>
        <!-- 保存按钮 -->
        <div style="display: flex; justify-content: center; margin-top: 3vw">
          <el-button type="primary" @click="subMit()">保存</el-button>
        </div>
      </van-tab>
      <van-tab title="出库提交">
        <div>
          <div
            style="
              width: 98%;
              height: 10vw;
              background: #4bc4fa;
              margin-left: 1%;
              margin-top: 2vw;
              border-radius: 2vw 2vw 0 0;
              display: flex;
              justify-content: space-around;
              align-items: center;
              color: #fff;
            "
          >
            <div>日期</div>
            <div>流水号</div>
            <div>供应商名称</div>
            <div>详情</div>
          </div>
          <div
            v-for="(item, index) in detailSubList"
            :key="index"
            style="
              display: flex;
              width: 98%;
              height: 10vw;
              margin-left: 1%;
              color: #000;
              background: rgba(190, 190, 190, 0.2);
              display: flex;
              justify-content: space-around;
              align-items: center;
            "
          >
            <div style="width: 15vw; margin-left: 3vw">
              <el-tooltip placement="top">
                <div slot="content">{{ item.bill_date }}</div>
                <div
                  style="overflow: hidden; white-space: nowrap; width:10.5vw"
                >
                  {{ item.bill_date }}
                </div>
              </el-tooltip>
            </div>
            <div style="width: 15vw; margin-right: 10vw">
              <el-tooltip placement="top">
                <div slot="content">{{ item.bill_code }}</div>
                <div style="overflow: hidden; white-space: nowrap; width: 20vw">
                  {{ item.bill_code }}
                </div>
              </el-tooltip>
            </div>
            <div style="width: 15vw; margin-right: 8vw">
              <el-tooltip placement="top">
                <div slot="content">{{ item.ref_user_name }}</div>
                <div style="overflow: hidden; white-space: nowrap; width: 13vw">
                  {{ item.ref_user_name }}
                </div>
              </el-tooltip>
            </div>
            <div
              style="color: green; margin-right: 4vw"
              @click="subEdit(item, barCodeOutScan)"
            >
              编辑
            </div>
          </div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { Toast } from "vant";
export default {
  data() {
    return {
      value: "",
      throughtList: [
        { id: 201, name: "销售出库" },
        { id: 202, name: "退货出库" },
        { id: 203, name: "调拨出库" },
        { id: 204, name: "返工出库" },
        { id: 205, name: "销毁出库" },
        { id: 206, name: "抽检出库" },
        { id: 207, name: "直调出库" },
        { id: 208, name: "生产出库" },
        { id: 209, name: "供应出库" },
        { id: 211, name: "召回出库" },
        { id: 212, name: "赠品出库" },
        { id: 214, name: "盘亏出库" },
        { id: 215, name: "损坏出库" },
        { id: 216, name: "报废出库" },
        { id: 217, name: "其他出库" },
        { id: 237, name: "直调退货" },
      ], //入库类型
      vonderList: [
        { id: "101", name: "北京" },
        { id: "102", name: "大连" },
        { id: "103", name: "上海" },
        { id: "104", name: "杭州" },
        { id: "106", name: "苏州" },
        { id: "107", name: "河南" },
        { id: "108", name: "抚顺" },
        { id: "109", name: "沈阳" },
        { id: "111", name: "广州" },
        { id: "112", name: "大庆" },
      ],
      packList: [
        { id: "001", name: "大包装" },
        { id: "002", name: "中包装" },
        { id: "003", name: "小包装" },
      ],
      query: {
        startTime: "",
        endTime: "",
      },
      choose_popup: false,
      maxDate: 0, //时间不超过今天
      currentDate: 0, //用户选中的时间
      dateVaule: "",
      restaurants: [],
      state: "", //供应商搜索
      pack: "",
      goodsVaule: "",
      tmpBarCodeOut: "",
      goodsE: {},
      codeListchuku: [],
      codeList1: [],
      arrayChuku: [],
      array1: [],
      lastArray: [],
      lastArray1: [],
      ll: [],
      valus: "",
      inoubill: [],
      shuzuChuku: [],

      saveListScan: {},
      ItemList: [],
      goodsVauleMed: "",
      goodsList2: [],
      show1: false,
      jianma: true,
      shoudong: false,
      codeListS: [],
      codeListY: [],
      codeArrayChuku: [],
      codeArrayChukuTwo: [],
      itemChukuList: [],
      barCodeOutScan:"",
      detailSubList:[]
    };
  },

  components: {},

  computed: {},

  mounted() {
    this.barCodeOutScan = this.$route.params.barCodeOutScan;
    this.inoutbillList()
    this.state = localStorage.getItem("torefName");
    if (JSON.parse(localStorage.getItem("arrayChuku")) != null) {
      this.shuzuChuku = JSON.parse(localStorage.getItem("arrayChuku"));
    } else {
      this.shuzuChuku = [];
    }
    // code码
    if (JSON.parse(localStorage.getItem("codeArrayChuku")) != null) {
      this.codeArrayChuku = JSON.parse(localStorage.getItem("codeArrayChuku"));
    } else {
      this.codeArrayChuku = [];
    }
    this.goods();
    this.pack = "小包装";
    // this.vonderValue = "106"; //供应商默认
    this.value = 201; //入库类型默认
    let new_time = new Date();
    this.maxDate = new_time;
    this.currentDate = new_time;
    let month = this.currentDate.getMonth() + 1;
    let newMonth = month >= 10 ? month : "0" + month;
    let day = this.currentDate.getDate();
    let newDay = day >= 10 ? day : "0" + day;
    let tie = `${this.currentDate.getFullYear()}-${newMonth}-${newDay}`;
    this.dateVaule = tie;

    let index = this.$route.params.index;

    localStorage.setItem("index", index);

    if (this.$route.params.barCodeOutScan != "undefined") {
      this.tmpBarCodeOut = this.$route.params.barCodeOutScan;
      this.gooodsScan(this.tmpBarCodeOut);
    }
  },

  methods: {
       // 入库待提交单据列表
       inoutbillList() {
      this.$axios.post(this.$api.inoutbillList1+ "?billType=" + 201).then((res) => {
        console.log(res, "详情列表");
        this.detailSubList = res.data.data;
      });
    },
    // 入库提交编辑
    subEdit(item, barCodeOutScanChuku) {
      localStorage.setItem("idChuku", item.id);
      this.$router.push(`./detailOutEdit/${barCodeOutScanChuku}`);
    },
    delChuku(index) {
      this.codeListchuku.splice(index, 1);
      localStorage.setItem("arrayChuku", JSON.stringify(this.codeListchuku));
      // this.codeArrayChuku.splice(index,1)
      // localStorage.setItem("codeArrayChuku",JSON.stringify(this.codeArrayChuku))
    },
    aaa() {
      this.jianma = false;
      this.shoudong = true;
    },
    // 选择商品
    onSelect(item) {
      this.a = item.goodsNo + "/" + item.goodsName;
      localStorage.setItem("chuku", this.a);
      this.goodsVaule = localStorage.getItem("chuku");
      this.medicDetails = item;
      this.$delete(this.medicDetails, "approvalNumber");
      this.$delete(this.medicDetails, "unit");
      this.$delete(this.medicDetails, "specification");
      this.$delete(this.medicDetails, "retailPrice");
      this.$delete(this.medicDetails, "production_unit");
      this.medicDetails.itemNo = this.medicDetails.goodsNo;
      this.medicDetails.itemName = this.medicDetails.goodsName;
      this.$delete(this.medicDetails, "goodsNo");
      this.$delete(this.medicDetails, "goodsName");
      console.log(this.medicDetails);
      localStorage.setItem(
        "goodsDetailchuku",
        JSON.stringify(this.medicDetails)
      );
      this.show1 = false;
      this.medicDetailsShow = true;
    },
    // 药品搜索触发的事件
    showPopup(valueMedcine) {
      console.log(valueMedcine);
      if (valueMedcine == "") {
        this.$toast({ message: "请输入商品名或货号", duration: 1500 });
        return false;
      }
      this.$axios
        .get(this.$api.searchGoods2DetailList, {
          params: {
            keyWord: valueMedcine,
          },
        })
        .then((res) => {
          this.goodsList2 = res.data.data;
          this.goodsListShow = res.data.data;
        });
      this.show1 = true;
      this.informatiobShow = false;
    },
    gooodsScan1(code) {
      if (JSON.parse(localStorage.getItem("codeArrayChuku")) != null) {
      this.codeArrayChuku = JSON.parse(localStorage.getItem("codeArrayChuku"));
    } else {
      this.codeArrayChuku = [];
    }
    if(this.codeArrayChuku.includes(code)== true){
        this.$toast({
          message:"药监码不能重复",
          duration:2000
        })
        this.codeListchuku = JSON.parse(localStorage.getItem("arrayChuku"));
        return false
      }else{
        this.codeArrayChuku.push(code)
        localStorage.setItem("codeArrayChuku",JSON.stringify(this.codeArrayChuku))

        this.shuzuChuku = JSON.parse(localStorage.getItem("arrayChuku"));
      if (this.$route.params.barCodeOutScan == "undefined") {
        this.shuzuChuku = [];
      }
      this.jianma = true;
      this.shoudong = false;
      this.goodsE = JSON.parse(localStorage.getItem("goodsDetailchuku"));
      this.goodsE.traceCode = code;
      this.goodsE.actualQuantity = 1;
      this.codeListS.push(this.goodsE);
      this.codeListchuku = [...this.shuzuChuku,...this.codeListS];
      localStorage.setItem("arrayChuku", JSON.stringify(this.codeListchuku));
      if (this.$route.params.barCodeOutScan == "undefined") {
        this.codeListS;
      } else {
        this.codeListS = [];
      }
      }

 
    },
    styleList(name) {
      console.log(name, "单据类型");
    },
    subMit() {
      console.log(this.dateVaule);
      console.log(this.value);
      console.log(localStorage.getItem("torefName"));
      console.log(localStorage.getItem("torefCode"));
      console.log(3, "普药");
      console.log(JSON.parse(localStorage.getItem("arrayChuku")));
      this.itemChukuList = JSON.parse(localStorage.getItem("arrayChuku"));

      this.saveListScan.billTime = this.dateVaule;
      this.saveListScan.billType = this.value;
      this.saveListScan.physicType = 3;
      this.saveListScan.toUserName = localStorage.getItem("torefName");
      this.saveListScan.toUserCode = localStorage.getItem("torefCode");
      this.saveListScan.inoutbillItemList = this.itemChukuList;

      // console.log(this.saveListScan, "最后对象");
      this.$axios
        .post(this.$api.saveInOutBillList, this.saveListScan)
        .then((res) => {
          console.log(res, "a");
          if (res.data.code == 0) {
            this.$toast({
              message: "保存成功",
              type: "success",
              duration: 3000,
            });
            this.codeListchuku=[]
            localStorage.removeItem("goodsDetailchuku");
            localStorage.removeItem("arrayChuku");
            localStorage.removeItem("codeArrayChuku");
            localStorage.removeItem("torefName");
            localStorage.removeItem("torefCode");
            localStorage.removeItem("chuku");
          } else {
            this.$toast({
              message: res.data.msg,

              duration: 3000,
            });
            return false;
          }
        });
    },
    // 获取全部供应商列表
    goods() {
      this.$axios
        .get(this.$api.getCustomerList, {
          params: {
            key: this.valus,
          },
        })
        .then((res) => {
          console.log(res, "aaa供应商");
          this.inoubill = res.data.data;
          this.restaurants = this.loadAll();
        });
    },
    // 如果扫描回来带回来code码的时候调用
    gooodsScan(code) {
      if(this.codeArrayChuku.includes(code)== true){
        this.$toast({
          message:"药监码不能重复",
          duration:2000
        })
        this.codeListchuku = JSON.parse(localStorage.getItem("arrayChuku"));
        return false
      }else{
        this.codeArrayChuku.push(code)
        localStorage.setItem("codeArrayChuku",JSON.stringify(this.codeArrayChuku))
        this.goodsE = JSON.parse(localStorage.getItem("goodsDetailchuku"));
      this.goodsE.traceCode = code.toString();
      this.goodsE.actualQuantity = 1;
      this.codeListchuku.push(this.goodsE);
      this.codeListchuku = [...this.shuzuChuku, ...this.codeListchuku];
      localStorage.setItem("arrayChuku", JSON.stringify(this.codeListchuku));
      }
     
    },

    // 编码扫描
    scanBianmachuku() {
      wx.miniProgram.navigateTo({
        url: `/pages/outScaning/outScaning?token=${localStorage.getItem(
          "token"
        )}&id=${localStorage.getItem("index")}`,
      });
    },

    // 商品 信息接口
    goodsQuery() {
      console.log(this.goodsVaule, "输入商品货号");
      if (this.goodsVaule == "") {
        this.$toast({
          message: "请输入商品货号",
          duration: 1000,
        });
        return false;
      }
      this.user_info = JSON.parse(localStorage.getItem("user_info"));
      this.$axios
        .get(this.$api.goodsStatisticsList, {
          params: {
            keyWord: this.goodsVaule,
            shopId: this.user_info.shopId,
          },
        })
        .then((res) => {
          this.goodsList = res.data.resultList[0];
          this.$delete(this.goodsList, "approval_number");
          this.$delete(this.goodsList, "origin");
          this.$delete(this.goodsList, "unit");
          this.$delete(this.goodsList, "title");
          this.$delete(this.goodsList, "specification");
          console.log(this.goodsList, "a");
          localStorage.setItem(
            "goodsDetailchuku",
            JSON.stringify(this.goodsList)
          );
        });
    },
    // 日期dialog的取消按钮
    cancelBtn() {
      this.choose_popup = false;
    },
    // 选择日期时间
    chooseDate(e) {
      this.choose_type = e;
      this.choose_popup = true;
      this.popup_type = 1;
    },
    // 日期dialog
    chooseDateBtn() {
      this.choose_popup = false;
      let month = this.currentDate.getMonth() + 1;
      let newMonth = month >= 10 ? month : "0" + month;
      let day = this.currentDate.getDate();
      let newDay = day >= 10 ? day : "0" + day;
      let tie = `${this.currentDate.getFullYear()}-${newMonth}-${newDay}`;

      console.log(tie);
      console.log(this.currentDate, "时间");

      // let choose_time = this.getYYYYMMDD(this.currentDate)
      if (this.choose_type == 1) {
        //选中开始时间
        this.query.startTime = tie;
        this.dateVaule = this.query.startTime;
        console.log(this.query.startTime, "开始时间");
      } else {
        this.query.endTime = tie;
        console.log(this.query.endTime, "结束时间");
      }
      this.currentDate = new Date();
    },
    // 返回按钮
    back() {
      this.codeListchuku = [];
      localStorage.removeItem("goodsDetailchuku");
      localStorage.removeItem("arrayChuku");
      localStorage.removeItem("codeArrayChuku");
      localStorage.removeItem("torefName");
      localStorage.removeItem("torefCode");
      localStorage.removeItem("chuku");
      this.$router.push("/point");
    },

    // 可选择可搜索
    querySearch(queryString, cb) {
      console.log(queryString, "aaaaa");
      console.log(cb, "aaaaa1111");
      var restaurants = this.restaurants;
      console.log(restaurants, "ddd");
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.客户名称
            .toLowerCase()
            .indexOf(queryString.toLowerCase()) === 0
        );
      };
    },

    loadAll() {
      console.log(this.inoubill, "客户");
      return this.inoubill;
    },
    handleSelect(item) {
      console.log(item, "选择");

      this.state = item.客户名称;
      localStorage.setItem("torefName", item.客户名称);
      localStorage.setItem("torefCode", item.客户代码);
      console.log(this.state, "a");
    },

    handleIconClick(ev) {
      console.log(ev);
    },
  },
};
</script>
<style  scoped>
.point_top {
  width: 100%;
  height: 6vh;
  background-image: linear-gradient(#04a9f3, #4ec5fb);
  color: #ffffff;
  font-size: 4.6vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.point_point {
  font-size: 6vw;
  font-weight: bolder;
  margin-right: 3vw;
}
.point_top i {
  width: 25px;
  height: 20px;
  margin-left: 3vw;
  font-weight: 600;
}
.point_main {
  width: 90%;
  height: 50vw;
  border-radius: 1vw;
  margin-left: 2%;
  margin-top: 1vw;
  padding: 3vw;
  /* background: url(../assets/icon/矩形\ 4\ 拷贝\ 13.png); */
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.every_option {
  /* height: 7vh; */
  display: flex;

  align-items: center;
}
.every_select {
  display: flex;
  font-size: 3.5vw;
  flex-wrap: nowrap;
  /* align-items:center; */
  /* border-top: 1px solid #E2E2E2; */
}
.my-autocomplete li {
  line-height: normal;
  padding: 7px;
}
.name {
  text-overflow: ellipsis;
  overflow: hidden;
}
.addr {
  font-size: 12px;
  color: #b4b4b4;
}

.highlighted .addr {
  color: #ddd;
}
</style>